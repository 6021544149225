import './App.css';
import logo from './images/logo.png'

function App() {
    return (
        <div className="app">
            <a id="logo" title="Ridef Italia" href="#">
                <img
                    src={logo} alt="Ridef Italia"/></a>
            <a className="link-db"
               href="https://www.dropbox.com/scl/fi/gu715ax4l8v8fiflqwfbw/A4-1.pdf?rlkey=42do60htefkaytnq9pfnkv7jo&dl=0"
               target="_blank" title="Documentazione Ridef">
                DOCUMENTAZIONE RIDEF<br/>
                RIDEF DOCUMENTATION<br/>
                DOCUMENTATION RIDEF<br/>
                DOCUMENTACIÓN RIDEF<br/>
                DOKUMENTATION RIDEF
            </a>
            <p className="link-p">
                Sponsors<br/>
                <a className='link' href='https://legambiente.it/'>Legambiente</a><br/>
                <a className='link' href='https://socialitaliani.com/'>SocialItaliani </a><br/>
                <a className='link' href='https://www.mce-fimem.it/'>MCE Finem</a>
            </p>
        </div>
    );
}

export default App;
